<template>
  <div class="header" style="background-color: white">
    <v-row class="title-row">
      <h3>{{ $t("Contacts") }}</h3>
      <AddNewButton />
    </v-row>

    <v-row style="padding-left: 104px; margin-top: 0px">
      <div
        @click="changeTab('contacts')"
        :class="
          activeTab === 'contacts'
            ? 'tab-selected content-small'
            : 'tab-unselected content-small'
        "
      >
        {{ $t("Contacts") }}
      </div>
      <div
        v-if="user.isAdmin || user.isGroupAdmin"
        @click="changeTab('organizations')"
        :class="
          activeTab === 'organizations'
            ? 'tab-selected content-small'
            : 'tab-unselected content-small'
        "
        style="margin-left: 24px"
      >
        {{ $t("Organizations") }}
      </div>
      <div
        @click="changeTab('partners')"
        :class="
          activeTab === 'partners'
            ? 'tab-selected content-small'
            : 'tab-unselected content-small'
        "
        style="margin-left: 24px"
      >
        {{ $t("Partners") }}
      </div>
    </v-row>

    <HeaderToolbar :module="'contact'" :top-margin="toolbarTopMargin">
      <div class="flex">
        <button
          name="add-contact-button"
          @click="addContact()"
          class="outlined-small-button"
          style="background-color: black !important"
        >
          <img
            src="../../assets/images/plusSign.svg"
            style="
              filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
                brightness(103%) contrast(102%);
            "
            alt=""
          />
          <p
            style="
              filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
                brightness(103%) contrast(102%);
            "
          >
            {{ $t("AddOneContact") }}
          </p>
        </button>

        <FilterDropdown
          v-if="activeTab === 'contacts'"
          ref="filterDropdown"
          :from-dashboard="savedViewId"
          filterName="contacts"
          @currentSelectedFilter="updateAdvancedFilters($event)"
          style="margin-left: 8px !important"
        />

        <span style="padding: 0 8px; align-self: center"> • </span>

        <div style="margin-left: 0px">
          <v-text-field
            autocomplete="off"
            outlined
            v-model="search"
            hide-details
            :id="'search-bar'"
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('Search') + '...'"
            style="background: white"
          >
          </v-text-field>
        </div>
        <div class="owner-tags-wrapper" v-if="activeTab === 'contacts'">
          <div
            v-for="tag in tags"
            :key="tag.name"
            @click="setTag(tag.propertyValue)"
            class="inside-tag"
            :id="tag.name"
          >
            <div
              v-bind:class="{
                'tag-background green-ground':
                  tag.name === 'Property owner' ||
                  tag.name === 'Kinnisvara omanik',
                'tag-background rose-ground':
                  tag.name === 'Tenant' || tag.name === 'Üürnik',
                'tag-background blue-ground':
                  tag.name === 'Landlord' || tag.name === 'Üürileandja',
                'tag-background yellow-ground':
                  tag.name === 'Buyer' || tag.name === 'Ostja',
                'tag-background grey-ground':
                  tag.name === 'Other' || tag.name === 'Muu',
                '': tag.name === 'Show all' || tag.name === 'Kuva kõik',
              }"
            >
              <p
                v-bind:class="{
                  'tag-name': tag.abbreviation !== '',
                  '': tag.abbreviation === '',
                }"
              >
                {{ tag.abbreviation.toUpperCase() }}
              </p>
            </div>

            <p
              v-if="tag.name !== 'Show all' && tag.name !== 'Kuva kõik'"
              :id="tag.abbreviation"
              class="content-small"
              style="margin-right: 8px"
            >
              {{ tag.name }}
            </p>
            <p
              v-if="tag.name === 'Show all' || tag.name === 'Kuva kõik'"
              id="special"
              class="content-small"
              style="margin-right: 8px"
            >
              {{ tag.name }}
            </p>
          </div>
        </div>
        <div class="owner-tags-wrapper" v-else-if="activeTab === 'partners'">
          <div
            v-for="tag in partnerTags"
            :key="tag.name"
            @click="setTag(tag.propertyValue)"
            class="inside-tag"
            :id="tag.name"
          >
            <div
              v-bind:class="{
                'tag-background turquoise-ground':
                  tag.propertyValue === 'photographer',
                'tag-background purple-ground':
                  tag.propertyValue === 'valuator',
                'tag-background sky-blue-ground':
                  tag.propertyValue === 'notary',
                'tag-background gray-ground':
                  tag.propertyValue === 'otherInPartner',
                'tag-background iron-red-ground':
                  tag.propertyValue === 'bank_representative',
                '': tag.propertyValue === 'show_all',
              }"
            >
              <p
                v-bind:class="{
                  'tag-name white-tag-text': tag.abbreviation !== '',
                  '': tag.abbreviation === '',
                }"
              >
                {{ tag.abbreviation.toUpperCase() }}
              </p>
            </div>

            <p
              v-if="tag.name !== 'Show all' && tag.name !== 'Kuva kõik'"
              :id="tag.abbreviation"
              class="content-small"
              style="margin-right: 8px"
            >
              {{ tag.name }}
            </p>
            <p
              v-if="tag.name === 'Show all' || tag.name === 'Kuva kõik'"
              id="special"
              class="content-small"
              style="margin-right: 8px"
            >
              {{ tag.name }}
            </p>
          </div>
        </div>
        <div
          @click="openExtraFilters"
          v-if="activeTab === 'contacts'"
          style="
            cursor: pointer;
            margin-left: 12px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
          "
        >
          <p style="align-self: center" class="link-small">
            {{ $t("Filters") }}
          </p>
          <img
            style="
              width: 16px;
              height: 16px;
              margin-left: 8px;
              cursor: pointer;
              align-self: center;
            "
            src="../../assets/images/chevron.svg"
            alt=""
            v-bind:class="{
              up: filtersOpen,
              down: !filtersOpen,
            }"
          />
        </div>
      </div>

      <ExcelExport
        :fields="csvFields"
        :rowData="contacts"
        :filename="getFileName()"
        sheetName="contacts"
        @getExportData="getExportData"
      />

      <div
        v-if="filtersOpen && activeTab === 'contacts'"
        style="
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          gap: 8px;
          margin-top: 32px;
        "
      >
        <div class="filter-section">
          <v-row>
            <p class="label12">{{ $t("Interests") }}</p>
          </v-row>
          <div class="inputs-wrapper">
            <DropdownMulti
              :items="transactionFilters"
              v-model="filterTransactions"
              :display-key="'name'"
              :width="'168px'"
              :height="'34px'"
              :font-size="'12px'"
              :value-key="'name'"
              :default-value="$t('AllTransactionTypes')"
              :translate="true"
            />
            <DropdownMulti
              :items="estateFilters"
              v-model="filterEstates"
              :display-key="'name'"
              :width="'168px'"
              :height="'34px'"
              :font-size="'12px'"
              :value-key="'name'"
              :default-value="$t('AllEstateTypes')"
              :translate="true"
            />
          </div>
        </div>
        <div class="filter-section">
          <v-row>
            <p class="label12">{{ $t("Birthday") }}</p>
          </v-row>
          <v-row class="inputs-wrapper">
            <Dropdown
              v-if="advancedFiltersUpdater"
              :items="birthdaySelections"
              :display-key="'name'"
              :value-key="'value'"
              :default-item="birthdaySelections[0]"
              :selected="selectedBirthdaySection"
              @itemSelected="filterByBirthdaySelection"
            />
          </v-row>
        </div>
        <div class="filter-section">
          <v-row>
            <p class="label12">{{ $t("Ownership") }}</p>
          </v-row>
          <div class="inputs-wrapper">
            <DropdownMulti
              :items="ownershipFilters"
              v-model="filterOwnership"
              :display-key="'name'"
              :value-key="'name'"
              :width="'168px'"
              :height="'34px'"
              :font-size="'12px'"
              :default-value="$t('AllOwnershipTypes')"
              :translate="true"
            />
            <GroupOrUserSelect
              v-model="chosenUsers"
              :allow-only-first-level-data="true"
            />
          </div>
        </div>
        <div class="filter-section">
          <v-row>
            <p class="label12">{{ $t("Created") }}</p>
          </v-row>

          <div class="inputs-wrapper">
            <Dropdown
              v-if="pastDayOptions"
              :items="pastDayOptions"
              :display-key="'name'"
              :value-key="'name'"
              :default-item="pastDayOptions[0]"
              :selected="selectedCreationDate"
              @itemSelected="filterByCreatedDate"
            />
          </div>
        </div>
      </div>
    </HeaderToolbar>
    <AddModal
      @closed="updateCustomers"
      @openClientDetailPanel="$emit('openClientPanel')"
      v-if="addModalOpen"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddModal from "../Kliendiregister/AddModal.vue";
import AddNewButton from "@/components/common/AddNew.vue";
import HeaderToolbar from "@/components/common/HeaderToolbar";
import FilterDropdown from "@/components/common/FilterDropdown";
import DropdownMulti from "@/components/common/DropdownMulti";
import Dropdown from "@/components/common/Dropdown";
import nanoid from "nanoid";

import ExcelExport from "@/components/Kliendiregister/ExcelExport";
import contactApi from "@/http/contact";
import GroupOrUserSelect from "@/components/FormElements/GroupOrUserSelect";
import moment from "moment";

export default {
  name: "Kliendiregister",
  props: ["clearFilters", "loaded", "contactFilters"],
  components: {
    ExcelExport,
    AddModal,
    AddNewButton,
    HeaderToolbar,
    DropdownMulti,
    FilterDropdown,
    Dropdown,
    GroupOrUserSelect
  },
  data() {
    return {
      chosenUsers: null,
      contacts: null,
      csvFields: [
        "customerId",
        "clientOwnership",
        "customerName",
        "email",
        "phoneNumber",
        "personalCode",
        "birthday",
        "address",
        "city",
        "sex",
        "organization",
        "contactIsPartner",
        "maritalStatus",
        "propertyOwner",
        "landlord",
        "buyer",
        "tenant",
        "other",
        "creator",
        "createdAt",
      ],
      activeTab: "contacts",
      savedViewId: null,
      chosenBrokerId: null,
      brokerId: "",
      selectedUser: null,
      selectedBirthdaySection: null,
      selectedCreationDate: null,
      colleagueUpdater: 0,
      currentActiveFilter: null,
      advancedFiltersUpdater: 0,
      filtersOpen: false,
      search: "",
      addModalOpen: false,
      dropdownOpen: false,
      contactFiltersDropdownOpen: false,
      tagValue: "show_all",
      sectorTypes: ["All", "Juridical persons", "Private persons"],
      chosenSectorType: "All",
      tagAbbreviation: null,
      partnerTags: {
        show_all: {
          name: this.$t("ShowAll"),
          abbreviation: "",
          propertyValue: "show_all",
          isClicked: false,
        },
        photographer: {
          name: this.$t("Photographer"),
          propertyValue: "photographer",
          abbreviation: this.$t("PH"),
          isClicked: false,
        },
        valuator: {
          name: this.$t("Valuator"),
          propertyValue: "valuator",
          abbreviation: this.$t("VA"),
          isClicked: false,
        },
        notary: {
          name: this.$t("Notary"),
          propertyValue: "notary",
          abbreviation: this.$t("NO"),
          isClicked: false,
        },
        bank_representative: {
          name: this.$t("BankRepresentative"),
          propertyValue: "bank_representative",
          abbreviation: this.$t("BA"),
          isClicked: false,
        },
        otherInPartner: {
          name: this.$t("Other"),
          propertyValue: "otherInPartner",
          abbreviation: this.$t("O"),
          isClicked: false,
        },
      },
      tags: {
        show_all: {
          name: this.$t("ShowAll"),
          abbreviation: "",
          propertyValue: "show_all",
          isClicked: false,
        },
        property_owner: {
          name: this.$t("PropertyOwner"),
          propertyValue: "property_owner",
          abbreviation: this.$t("PO"),
          isClicked: false,
        },
        landlord: {
          name: this.$t("CustomerField.CustomerType.Options.LANDLORD"),
          abbreviation: this.$t("L"),
          propertyValue: "landlord",
          isClicked: false,
        },
        buyer: {
          name: this.$t("CustomerField.CustomerType.Options.BUYER"),
          abbreviation: this.$t("B"),
          propertyValue: "buyer",
          isClicked: false,
        },
        tenant: {
          name: this.$t("CustomerField.CustomerType.Options.TENANT"),
          abbreviation: this.$t("T"),
          propertyValue: "tenant",
          isClicked: false,
        },
        other: {
          name: this.$t("CustomerField.CustomerType.Options.OTHER"),
          abbreviation: this.$t("O"),
          propertyValue: "other",
          isClicked: false,
        },
      },
      colleagues: [
        { name: this.$t("AllBrokers"), value: "allBrokers", _id: nanoid(16) },
      ],
      filterTransactions: [],
      filterEstates: [],
      filterOwnership: [],
      estateFilters: [
        {
          name: "CommercialSpace",
          value: "commercial",
        },
        { name: "Apartment", value: "apartment" },
        { name: "House", value: "house" },
        { name: "HousePart", value: "house_part" },
        { name: "LandPlot", value: "land" },
        { name: "SummerHouse", value: "cottage" },
        { name: "Garage", value: "garage" },
      ],
      ownershipFilters: [
        { name: "MyContacts", value: "PRIVATE" },
        { name: "DepartmentContacts", value: "GROUP" },
        { name: "CompanyContacts", value: "COMPANY" },
      ],
      transactionFilters: [
        { name: "Purchase", value: "sale" },
        { name: "Rent", value: "rent" },
        {
          name: "ShortTermRent",
          value: "short_term_rent",
        },
      ],
    };
  },
  watch: {
    chosenUsers: async function (value) {
      await this.filter({
        filterName: "contacts",
        conditionName: "brokerId",
        value: value,
        valueTitle: "",
      });
    },
    clearFilters() {
      const some = JSON.parse(
        JSON.stringify(this.filters["contacts"][0].conditions)
      );
      this.setProvisionalFilter({
        filterName: "contacts",
        conditions: some,
      });
      this.advancedFiltersUpdater++;
    },
    filtersOpen: function () {
      this.$emit("filtersOpen", this.filtersOpen);
    },
    uploadFile(index) {
      const fileContentLoaded = setInterval(() => {
        if (document.getElementById("interior-uploader-" + index)) {
          document.getElementById("interior-uploader-" + index).click();
          clearInterval(fileContentLoaded);
        }
      }, 100);
    },
    provisionalFiltersUpdater() {
      const conditions = this.provisionalFilters["contacts"];
      this.filterByConditions(conditions);
    },
    
    search: async function (val) {
      await this.filter({
        filterName: "contacts",
        conditionName: "search",
        value: val,
      });
    },
    filterTransactions: {
      handler(newVal) {
        let transactionsArray = [];
        newVal.forEach((transaction) => {
          transactionsArray.push(transaction.value);
        });
        this.filter({
          filterName: "contacts",
          conditionName: "transaction",
          value: transactionsArray,
          valueTitle: transactionsArray.join(", "),
        });
      },
      deep: true,
    },
    filterEstates: {
      handler(newVal) {
        let estatesArray = [];
        newVal.forEach((estate) => {
          estatesArray.push(estate.value);
        });
        this.filter({
          filterName: "contacts",
          conditionName: "estate",
          value: estatesArray,
          valueTitle: estatesArray.join(", "),
        });
      },
      deep: true,
    },
    filterOwnership: {
      handler(newVal) {
        let ownershipArray = [];
        newVal.forEach((ownership) => {
          ownershipArray.push(ownership.value);
        });
        this.filter({
          filterName: "contacts",
          conditionName: "ownership",
          value: ownershipArray,
          valueTitle: ownershipArray.join(", "),
        });
      },
      deep: true,
    },
  },
  async created() {
    await this.getColleagues();
    if (
      this.filters.contacts &&
      this.filters.contacts.some(
        (filterContact) => filterContact.isDefault && filterContact.activeTab
      )
    ) {
      const defaultFilter = this.filters.contacts.find(
        (filterContact) => filterContact.isDefault && filterContact.activeTab
      );
      await this.setActiveContactsTab(defaultFilter.activeTab);
      this.activeTab = defaultFilter.activeTab;
    } else {
      await this.setActiveContactsTab("contacts");
    }
  },
  mounted() {
    this.setDefaultTag();
    let dropdownItems = this.filters["contacts"];
    let conditions;
    this.savedViewId = this.$route.params.savedViewId;

    if (this.savedViewId) {
      conditions = dropdownItems.find(
        (dropdownItem) => dropdownItem._id === this.savedViewId
      ).conditions;
      if (!conditions.find((condition) => condition.name === "birthday")) {
        conditions.push({
          name: "birthday",
          title: "Birthday",
          value: "All",
          valueTitle: "All",
          items: [],
        });
      }
    } else {
      conditions = dropdownItems.find(
        (dropdownItem) => dropdownItem.isDefault
      ).conditions;
      if (!conditions.find((condition) => condition.name === "birthday")) {
        conditions.push({
          name: "birthday",
          title: "Birthday",
          value: "All",
          valueTitle: "All",
          items: [],
        });
      }
    }
    if (
      this.currentActiveFilter &&
      this.currentActiveFilter.filterTitle === "MyContactsOnly"
    ) {
      const brokerFilter = conditions.find((filter) => filter.name === "brokerId")
      if (!brokerFilter.value) {
        brokerFilter.value = {type: "user", value: this.user._id}
      }
      const some = JSON.parse(
        JSON.stringify(this.filters["contacts"][0].conditions)
      );
      this.setProvisionalFilter({
        filterName: "contacts",
        conditions: some,
      });
      this.advancedFiltersUpdater++;
    }
    this.filterByConditions(conditions);
  },
  async beforeDestroy() {
    await this.setActiveContactsTab("contacts");
  },
  computed: {
    ...mapGetters([
      "customerFilter",
      "filtersItemsContact",
      "provisionalFiltersUpdater",
      "provisionalFilters",
      "filters",
      "activeContactsTab",
      "user",
      "users",
      "language",
    ]),
    tagsToProcess() {
      return { ...this.tags, ...this.partnerTags };
    },
    toolbarTopMargin() {
      if (this.filtersOpen) {
        return "140px";
      } else {
        return null;
      }
    },
    birthdaySelections() {
      return [
        { name: this.$t("All"), value: "All" },
        { name: this.$t("Next7Days"), value: "next_7_days" },
        { name: this.$t("CurrentMonth"), value: "current_month" },
        { name: this.$t("January"), value: "1" },
        { name: this.$t("February"), value: "2" },
        { name: this.$t("March"), value: "3" },
        { name: this.$t("April"), value: "4" },
        { name: this.$t("May"), value: "5" },
        { name: this.$t("June"), value: "6" },
        { name: this.$t("July"), value: "7" },
        { name: this.$t("August"), value: "8" },
        { name: this.$t("September"), value: "9" },
        { name: this.$t("October"), value: "10" },
        { name: this.$t("November"), value: "11" },
        { name: this.$t("December"), value: "12" },
      ];
    },
    pastDayOptions() {
      return [
        {
          name: this.$t("All"),
          value: null,
        },
        {
          name: this.$t("Today"),
          value: "today",
        },
        {
          name: this.$t("Yesterday"),
          value: "yesterday",
        },
        {
          name: this.$t("InLast") + " 3 " + this.$t("InLastDays"),
          value: "last_3_days",
        },
        {
          name: this.$t("InLast") + " 14 " + this.$t("InLastDays"),
          value: "last_14_days",
        },
        {
          name: this.$t("ThisMonth"),
          value: "this_month",
        },
        {
          name: this.$t("LastMonth"),
          value: "last_month",
        },
        {
          name: this.$t("InLast") + " 3 " + this.$t("InLastMonths"),
          value: "last_3_months",
        },
        {
          name: this.$t("CurrentYear"),
          value: "current_year",
        },
        {
          name: this.$t("InLast") + " 12 " + this.$t("InLastMonths"),
          value: "last_year",
        },
      ];
    },
  },
  methods: {
    ...mapActions([
      "getUsers",
      "setActiveContactsTab",
      "setProvisionalFilter",
      "filter",
    ]),
    async getExportData() {
      const _contacts = (
        await contactApi.getContactsByFilters(this.contactFilters)
      ).filteredContacts;
      _contacts.forEach((contact) => {
        Object.keys(contact.tags).forEach((key) => {
          contact[key] = contact.tags[key];
        });
        contact.creator = contact.creator.name;
      });

      this.contacts = _contacts;
    },
    getFileName() {
      return "rp contacts-" + this.user.name + `${moment().format()}`;
    },
    async getColleagues() {
      this.users = await this.getUsers();
      let chosenBroker = null;
      this.users.forEach((user) => {
        if (user.userStatus === "Active") {
          this.colleagues.push(user);
        }
        if (user._id === this.brokerId) {
          chosenBroker = user;
        }
      });
      this.selectedUser = chosenBroker;
      this.colleagueUpdater++;
    },
    addContact() {
      this.addModalOpen = true;
    },
    updateCustomers() {
      this.$emit("updated");
      this.addModalOpen = false;
    },
    async updateAdvancedFilters(event) {
      await this.changeTab("contacts");
      this.currentActiveFilter = event;
      if (this.filters["contacts"][0].filterTitle === 'AllContacts') {
        this.filters["contacts"][0].filterTitle = 'MyContactsOnly'
      }
      if (this.filters["contacts"][0].filterTitle === 'MyContactsOnly') {
        this.filters["contacts"][0].conditions.find((condition) => condition.name === 'brokerId').value = {type: "user", value: this.user._id}
      }
      if (
        this.currentActiveFilter &&
        this.currentActiveFilter.filterTitle !== "MyContactsOnly"
      ) {
        this.currentActiveFilter.conditions.forEach((item) => {
          if (item.name === "birthday") {
            this.selectedBirthdaySection = {
              name: item.valueTitle,
              value: item.value,
            };
          }
          if (item.name === "brokerId") {
            if (item.value) {
              this.selectedUser = item.value;
            } else {
              this.selectedUser = {type: "user", value: this.user._id};
            }
          }
        });
      } else {
        this.selectedBirthdaySection = this.birthdaySelections[0];
        this.selectedUser = this.user;
        this.chosenUsers = {type: "user", value: this.user._id}
      }
      this.colleagueUpdater++;
      this.advancedFiltersUpdater++;
    },
    openExtraFilters() {
      this.filtersOpen = !this.filtersOpen;
    },
    async filterByTransactionType(transactionTypes) {
      let transactionsArray = [];
      transactionTypes.forEach((transaction) => {
        transactionsArray.push(transaction.value);
      });
      await this.filter({
        filterName: "contacts",
        conditionName: "transaction",
        value: transactionsArray,
        valueTitle: transactionsArray.join(", "),
      });
    },
    async filterByBirthdaySelection(birthdaySelection) {
      await this.filter({
        filterName: "contacts",
        conditionName: "birthday",
        value: birthdaySelection.value,
        valueTitle: birthdaySelection.name,
      });
    },
    async filterByEstateType(estateTypes) {
      let estatesArray = [];
      estateTypes.forEach((estate) => {
        estatesArray.push(estate.value);
      });
      await this.filter({
        filterName: "contacts",
        conditionName: "estate",
        value: estatesArray,
        valueTitle: estatesArray.join(", "),
      });
    },
    async filterByOwnershipType(ownershipTypes) {
      let ownershipsArray = [];
      ownershipTypes.forEach((ownership) => {
        ownershipsArray.push(ownership.value);
      });
      await this.filter({
        filterName: "contacts",
        conditionName: "ownership",
        value: ownershipsArray,
        valueTitle: ownershipsArray.join(", "),
      });
    },
    ...mapActions(["filter"]),
    filterByConditions(conditions) {
      conditions.forEach((condition) => {
        switch (condition.name) {
          case "search":
            this.search = condition.value;
            break;
          case "tag":
            this.tagValue = condition.value;
            this.setTagUI();
            break;
          case "transaction":
            if (condition.value === "[0].allTransactionTypes") {
              condition.value = [];
            }
            break;
          case "estate":
            if (condition.value === "[0].allEstateTypes") {
              condition.value = [];
            }
            break;
          case "ownership":
            if (condition.value === "[0].allOwnershipTypes") {
              condition.value = "MyContacts";
            }
            break;
          case "createdAt":
            if (condition.value) {
              this.selectedCreationDate = this.pastDayOptions.find((item) => item.value === condition.value)
            } else {
              this.selectedCreationDate = this.pastDayOptions[0]
            }
            break;
          case "brokerId":
            if (condition.value) {
              if (
                !condition.value.type ||
                (condition.value.type && !condition.value.value)
              ) {
                condition.value = null;
              }
            } else {
              condition.value = null;
            }
            this.chosenUsers = condition.value;
        }
      });
    },
    setDefaultTag() {
      document.getElementById(this.$t("ShowAll")).style.backgroundColor =
        "#F4F5F7";
      document.getElementById(this.$t("ShowAll")).style.borderTopLeftRadius =
        "7px";
      document.getElementById(this.$t("ShowAll")).style.borderBottomLeftRadius =
        "7px";
      document.getElementById("special").style.fontWeight = "500";
    },
    setTagUI() {
      for (const [item] of Object.entries(this.tagsToProcess)) {
        if (item === this.tagValue) {
          for (const [key, value] of Object.entries(this.tagsToProcess[item])) {
            if (key === "name") {
              document.getElementById(value).style.backgroundColor = "#F4F5F7";
            } else if (key === "abbreviation" && !value) {
              document.getElementById("special").style.fontWeight = "500";
            } else if (key === "abbreviation") {
              document.getElementById(value).style.fontWeight = "500";
            }
          }
        } else {
          for (const [key, value] of Object.entries(this.tagsToProcess[item])) {
            if (key === "name") {
              if (document.getElementById(value)) {
                document.getElementById(value).style.backgroundColor =
                  "initial";
              }
            } else if (key === "abbreviation" && !value) {
              document.getElementById("special").style.fontWeight = "400";
            } else if (key === "abbreviation") {
              if (document.getElementById(value)) {
                document.getElementById(value).style.fontWeight = "400";
              }
            }
          }
        }
      }
    },
    async setTag(val) {
      await this.filter({
        filterName: "contacts",
        conditionName: "tag",
        value: val,
        valueTitle: val,
      });
    },
    async filterByCreatedDate(newTimeFrame) {
      await this.filter({
        filterName: "contacts",
        conditionName: "createdAt",
        value: newTimeFrame.value,
        valueTitle: newTimeFrame.name,
      });
    },
    async changeTab(tabName) {
      if (!this.loaded) return;
      await this.setActiveContactsTab(tabName);
      this.activeTab = this.activeContactsTab;
      if (this.activeContactsTab === "organizations") return;
      await this.$emit("updated");
      this.setTagUI();
    },
   

    ...mapActions(["changeClientAddModal"]),
  },
};
</script>

<style lang="scss" scoped>
.tab-row {
  padding-left: 104px;
  margin-top: 0px;
}

.tab-selected {
  padding-bottom: 24px;
  font-weight: 600;
  border-bottom: 2px solid black;
  cursor: pointer;
}

.tab-unselected {
  padding-bottom: 24px;
  cursor: pointer;
}

hr {
  flex-basis: 100%;
  height: 0;
  margin: 0;
  border: 0;
}
.from-to-filter {
  display: flex;
  gap: 8px;
}
.up {
  display: initial;
}
.down {
  display: initial;
  -ms-transform: rotate(180deg); /* IE 9 */
  transform: rotate(180deg);
}

.saving-filter-dropdown {
  background: black;
  color: white;
}

.left-row {
  justify-content: flex-start;
  .v-select {
    max-width: 200px;
  }
  .sector-type {
    margin-right: 42px;
  }
}
.right-row {
  justify-content: flex-end;
  .autocomplete {
    max-width: 300px;
    margin-right: 16px;
  }
  .icon-button {
    border: 1px solid #e4e4e4;
    height: 56px;
    display: flex;
    outline: none;
    padding: 16px;
    align-items: center;
    user-select: none;
    margin-right: 16px;
    margin-left: 16px;
    border-radius: 8px;

    justify-content: center;
  }
}
::v-deep .v-text-field .v-input__control .v-input__slot {
  min-height: 34px !important;
  max-height: 34px !important;
}
::v-deep .v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 5.5px;
}
.tag-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  padding-right: 2.5px;
  padding-left: 2.5px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  justify-content: center;
  align-content: center;
}
::v-deep .v-icon.v-icon {
  font-size: 18px;
}
::v-deep .theme--light.v-icon {
  color: black;
}

.owner-tags-wrapper {
  gap: 0px;
  display: flex;
  margin-left: 8px;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
  .inside-tag {
    display: flex;
    height: 32px;
    align-items: center;
    cursor: pointer;
    padding-left: 8px;
    border-right: 1px solid #e6e8ec;
  }
  .inside-tag:last-child {
    display: flex;
    height: 32px;
    align-items: center;
    cursor: pointer;
    padding-left: 8px;
    border-right: none;
  }
}
.tag-background {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
}
.white-tag-text {
  color: white;
}
.green-ground {
  background-color: #b5e7cf;
}
.rose-ground {
  background-color: #fcbae2;
}
.blue-ground {
  background-color: #b8d8ff;
}
.yellow-ground {
  background-color: #fff2b3;
}
.grey-ground {
  background-color: #c3c4c6;
}
.turquoise-ground {
  background-color: #16afb9;
}
.purple-ground {
  background-color: #aa086a;
}
.sky-blue-ground {
  background-color: #4c8dd9;
}
.gray-ground {
  background-color: #75787a;
}
.iron-red-ground {
  background-color: #b54616;
}

::v-deep .v-text-field__slot input {
  font: revert !important;
}
.inputs-wrapper {
  gap: 8px;
  display: flex;
  margin: 12px 0 0 0;
}
.filter-section {
  gap: 10px;
}
</style>
