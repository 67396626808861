var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header",
    staticStyle: {
      "background-color": "white"
    }
  }, [_c('v-row', {
    staticClass: "title-row"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("Contacts")))]), _c('AddNewButton')], 1), _c('v-row', {
    staticStyle: {
      "padding-left": "104px",
      "margin-top": "0px"
    }
  }, [_c('div', {
    class: _vm.activeTab === 'contacts' ? 'tab-selected content-small' : 'tab-unselected content-small',
    on: {
      "click": function ($event) {
        return _vm.changeTab('contacts');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Contacts")) + " ")]), _vm.user.isAdmin || _vm.user.isGroupAdmin ? _c('div', {
    class: _vm.activeTab === 'organizations' ? 'tab-selected content-small' : 'tab-unselected content-small',
    staticStyle: {
      "margin-left": "24px"
    },
    on: {
      "click": function ($event) {
        return _vm.changeTab('organizations');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Organizations")) + " ")]) : _vm._e(), _c('div', {
    class: _vm.activeTab === 'partners' ? 'tab-selected content-small' : 'tab-unselected content-small',
    staticStyle: {
      "margin-left": "24px"
    },
    on: {
      "click": function ($event) {
        return _vm.changeTab('partners');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Partners")) + " ")])]), _c('HeaderToolbar', {
    attrs: {
      "module": 'contact',
      "top-margin": _vm.toolbarTopMargin
    }
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('button', {
    staticClass: "outlined-small-button",
    staticStyle: {
      "background-color": "black !important"
    },
    attrs: {
      "name": "add-contact-button"
    },
    on: {
      "click": function ($event) {
        return _vm.addContact();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "filter": "invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)"
    },
    attrs: {
      "src": require("../../assets/images/plusSign.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticStyle: {
      "filter": "invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddOneContact")) + " ")])]), _vm.activeTab === 'contacts' ? _c('FilterDropdown', {
    ref: "filterDropdown",
    staticStyle: {
      "margin-left": "8px !important"
    },
    attrs: {
      "from-dashboard": _vm.savedViewId,
      "filterName": "contacts"
    },
    on: {
      "currentSelectedFilter": function ($event) {
        return _vm.updateAdvancedFilters($event);
      }
    }
  }) : _vm._e(), _c('span', {
    staticStyle: {
      "padding": "0 8px",
      "align-self": "center"
    }
  }, [_vm._v(" • ")]), _c('div', {
    staticStyle: {
      "margin-left": "0px"
    }
  }, [_c('v-text-field', {
    staticStyle: {
      "background": "white"
    },
    attrs: {
      "autocomplete": "off",
      "outlined": "",
      "hide-details": "",
      "id": 'search-bar',
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": _vm.$t('Search') + '...'
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _vm.activeTab === 'contacts' ? _c('div', {
    staticClass: "owner-tags-wrapper"
  }, _vm._l(_vm.tags, function (tag) {
    return _c('div', {
      key: tag.name,
      staticClass: "inside-tag",
      attrs: {
        "id": tag.name
      },
      on: {
        "click": function ($event) {
          return _vm.setTag(tag.propertyValue);
        }
      }
    }, [_c('div', {
      class: {
        'tag-background green-ground': tag.name === 'Property owner' || tag.name === 'Kinnisvara omanik',
        'tag-background rose-ground': tag.name === 'Tenant' || tag.name === 'Üürnik',
        'tag-background blue-ground': tag.name === 'Landlord' || tag.name === 'Üürileandja',
        'tag-background yellow-ground': tag.name === 'Buyer' || tag.name === 'Ostja',
        'tag-background grey-ground': tag.name === 'Other' || tag.name === 'Muu',
        '': tag.name === 'Show all' || tag.name === 'Kuva kõik'
      }
    }, [_c('p', {
      class: {
        'tag-name': tag.abbreviation !== '',
        '': tag.abbreviation === ''
      }
    }, [_vm._v(" " + _vm._s(tag.abbreviation.toUpperCase()) + " ")])]), tag.name !== 'Show all' && tag.name !== 'Kuva kõik' ? _c('p', {
      staticClass: "content-small",
      staticStyle: {
        "margin-right": "8px"
      },
      attrs: {
        "id": tag.abbreviation
      }
    }, [_vm._v(" " + _vm._s(tag.name) + " ")]) : _vm._e(), tag.name === 'Show all' || tag.name === 'Kuva kõik' ? _c('p', {
      staticClass: "content-small",
      staticStyle: {
        "margin-right": "8px"
      },
      attrs: {
        "id": "special"
      }
    }, [_vm._v(" " + _vm._s(tag.name) + " ")]) : _vm._e()]);
  }), 0) : _vm.activeTab === 'partners' ? _c('div', {
    staticClass: "owner-tags-wrapper"
  }, _vm._l(_vm.partnerTags, function (tag) {
    return _c('div', {
      key: tag.name,
      staticClass: "inside-tag",
      attrs: {
        "id": tag.name
      },
      on: {
        "click": function ($event) {
          return _vm.setTag(tag.propertyValue);
        }
      }
    }, [_c('div', {
      class: {
        'tag-background turquoise-ground': tag.propertyValue === 'photographer',
        'tag-background purple-ground': tag.propertyValue === 'valuator',
        'tag-background sky-blue-ground': tag.propertyValue === 'notary',
        'tag-background gray-ground': tag.propertyValue === 'otherInPartner',
        'tag-background iron-red-ground': tag.propertyValue === 'bank_representative',
        '': tag.propertyValue === 'show_all'
      }
    }, [_c('p', {
      class: {
        'tag-name white-tag-text': tag.abbreviation !== '',
        '': tag.abbreviation === ''
      }
    }, [_vm._v(" " + _vm._s(tag.abbreviation.toUpperCase()) + " ")])]), tag.name !== 'Show all' && tag.name !== 'Kuva kõik' ? _c('p', {
      staticClass: "content-small",
      staticStyle: {
        "margin-right": "8px"
      },
      attrs: {
        "id": tag.abbreviation
      }
    }, [_vm._v(" " + _vm._s(tag.name) + " ")]) : _vm._e(), tag.name === 'Show all' || tag.name === 'Kuva kõik' ? _c('p', {
      staticClass: "content-small",
      staticStyle: {
        "margin-right": "8px"
      },
      attrs: {
        "id": "special"
      }
    }, [_vm._v(" " + _vm._s(tag.name) + " ")]) : _vm._e()]);
  }), 0) : _vm._e(), _vm.activeTab === 'contacts' ? _c('div', {
    staticStyle: {
      "cursor": "pointer",
      "margin-left": "12px",
      "display": "flex",
      "flex-direction": "row",
      "flex-wrap": "nowrap"
    },
    on: {
      "click": _vm.openExtraFilters
    }
  }, [_c('p', {
    staticClass: "link-small",
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Filters")) + " ")]), _c('img', {
    class: {
      up: _vm.filtersOpen,
      down: !_vm.filtersOpen
    },
    staticStyle: {
      "width": "16px",
      "height": "16px",
      "margin-left": "8px",
      "cursor": "pointer",
      "align-self": "center"
    },
    attrs: {
      "src": require("../../assets/images/chevron.svg"),
      "alt": ""
    }
  })]) : _vm._e()], 1), _c('ExcelExport', {
    attrs: {
      "fields": _vm.csvFields,
      "rowData": _vm.contacts,
      "filename": _vm.getFileName(),
      "sheetName": "contacts"
    },
    on: {
      "getExportData": _vm.getExportData
    }
  }), _vm.filtersOpen && _vm.activeTab === 'contacts' ? _c('div', {
    staticStyle: {
      "display": "flex",
      "width": "100%",
      "flex-wrap": "wrap",
      "gap": "8px",
      "margin-top": "32px"
    }
  }, [_c('div', {
    staticClass: "filter-section"
  }, [_c('v-row', [_c('p', {
    staticClass: "label12"
  }, [_vm._v(_vm._s(_vm.$t("Interests")))])]), _c('div', {
    staticClass: "inputs-wrapper"
  }, [_c('DropdownMulti', {
    attrs: {
      "items": _vm.transactionFilters,
      "display-key": 'name',
      "width": '168px',
      "height": '34px',
      "font-size": '12px',
      "value-key": 'name',
      "default-value": _vm.$t('AllTransactionTypes'),
      "translate": true
    },
    model: {
      value: _vm.filterTransactions,
      callback: function ($$v) {
        _vm.filterTransactions = $$v;
      },
      expression: "filterTransactions"
    }
  }), _c('DropdownMulti', {
    attrs: {
      "items": _vm.estateFilters,
      "display-key": 'name',
      "width": '168px',
      "height": '34px',
      "font-size": '12px',
      "value-key": 'name',
      "default-value": _vm.$t('AllEstateTypes'),
      "translate": true
    },
    model: {
      value: _vm.filterEstates,
      callback: function ($$v) {
        _vm.filterEstates = $$v;
      },
      expression: "filterEstates"
    }
  })], 1)], 1), _c('div', {
    staticClass: "filter-section"
  }, [_c('v-row', [_c('p', {
    staticClass: "label12"
  }, [_vm._v(_vm._s(_vm.$t("Birthday")))])]), _c('v-row', {
    staticClass: "inputs-wrapper"
  }, [_vm.advancedFiltersUpdater ? _c('Dropdown', {
    attrs: {
      "items": _vm.birthdaySelections,
      "display-key": 'name',
      "value-key": 'value',
      "default-item": _vm.birthdaySelections[0],
      "selected": _vm.selectedBirthdaySection
    },
    on: {
      "itemSelected": _vm.filterByBirthdaySelection
    }
  }) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "filter-section"
  }, [_c('v-row', [_c('p', {
    staticClass: "label12"
  }, [_vm._v(_vm._s(_vm.$t("Ownership")))])]), _c('div', {
    staticClass: "inputs-wrapper"
  }, [_c('DropdownMulti', {
    attrs: {
      "items": _vm.ownershipFilters,
      "display-key": 'name',
      "value-key": 'name',
      "width": '168px',
      "height": '34px',
      "font-size": '12px',
      "default-value": _vm.$t('AllOwnershipTypes'),
      "translate": true
    },
    model: {
      value: _vm.filterOwnership,
      callback: function ($$v) {
        _vm.filterOwnership = $$v;
      },
      expression: "filterOwnership"
    }
  }), _c('GroupOrUserSelect', {
    attrs: {
      "allow-only-first-level-data": true
    },
    model: {
      value: _vm.chosenUsers,
      callback: function ($$v) {
        _vm.chosenUsers = $$v;
      },
      expression: "chosenUsers"
    }
  })], 1)], 1), _c('div', {
    staticClass: "filter-section"
  }, [_c('v-row', [_c('p', {
    staticClass: "label12"
  }, [_vm._v(_vm._s(_vm.$t("Created")))])]), _c('div', {
    staticClass: "inputs-wrapper"
  }, [_vm.pastDayOptions ? _c('Dropdown', {
    attrs: {
      "items": _vm.pastDayOptions,
      "display-key": 'name',
      "value-key": 'name',
      "default-item": _vm.pastDayOptions[0],
      "selected": _vm.selectedCreationDate
    },
    on: {
      "itemSelected": _vm.filterByCreatedDate
    }
  }) : _vm._e()], 1)], 1)]) : _vm._e()], 1), _vm.addModalOpen ? _c('AddModal', {
    on: {
      "closed": _vm.updateCustomers,
      "openClientDetailPanel": function ($event) {
        return _vm.$emit('openClientPanel');
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }