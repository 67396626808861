import { render, staticRenderFns } from "./Kliendiregister.vue?vue&type=template&id=486a0d5f&scoped=true"
import script from "./Kliendiregister.vue?vue&type=script&lang=js"
export * from "./Kliendiregister.vue?vue&type=script&lang=js"
import style0 from "./Kliendiregister.vue?vue&type=style&index=0&id=486a0d5f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486a0d5f",
  null
  
)

export default component.exports